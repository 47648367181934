import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private tokenKey ='admin-token'

  public setToken(token:string) {
    localStorage.setItem(this.tokenKey, token)
  }

  public getToken() {
    if(localStorage.getItem(this.tokenKey)) {
      return localStorage.getItem(this.tokenKey)
    }else{
      return null
    }
  }

  public hasToken():boolean {
    return !!this.getToken();
  }

  public removeToken() {
    localStorage.removeItem(this.tokenKey)
    return !this.hasToken();
  }

}
