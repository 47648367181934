import {Injectable, signal, WritableSignal} from "@angular/core";

export interface UserModel {
  id?:number
  f_name:string
  l_name:string
  company_email:string
  personal_email:string
  contact_telephone:any
  management:boolean
  auth_token:string
  full_name:string
}

@Injectable({
  providedIn:"root"
})
export class User {

  userInitialData:UserModel = {
    auth_token: "",
    company_email: "",
    contact_telephone: undefined,
    f_name: "",
    l_name: "",
    management: false,
    personal_email: "",
    full_name:""
  }

  public details:WritableSignal<UserModel> = signal(this.userInitialData)

  public updateUser(details:UserModel) {
    this.details.set(details)
  }

  public isEmpty():boolean {
    return !this.details().auth_token
  }

}
