import {environment} from "../../environments/environment";


function bind(endpoint:string):string{
  return `${environment.baseUrl}/${endpoint}`
}

const endpoints:any = {
  login :'login', //post
  getMyDetails:'get-my-details',//post
  reports:{
    homeReports : 'reports/home'//post
  },
  customers:{
    all:'customers/all',
    customer:'customers/get-customer'
  }
}

export const login:string = bind(endpoints.login)
export const getMyDetails:string = bind(endpoints.getMyDetails)
export const homeReports:string = bind(endpoints.reports.homeReports)
export const allCustomers:string = bind(endpoints.customers.all)
export const getCustomer:string = bind(endpoints.customers.customer)
