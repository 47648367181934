import {computed, inject, Injectable, signal, WritableSignal} from '@angular/core';
import {TokenService} from "./token.service";
import {User} from "../../repositories/User";

@Injectable({
  providedIn: 'root'
})

export class AuthenticateService {

  private token = inject(TokenService)

  public isAuthenticated:WritableSignal<boolean> = signal(false)

  public authenticate(token:string) {
    this.token.setToken(token)
    this.isAuthenticated.set(true)
  }

  public deAuthenticate() {
    this.token.removeToken()
    this.isAuthenticated.set(false)
  }

  public isAuth() {
    //check if the token exists
    if (this.token.hasToken()) {
      if(!this.isAuthenticated()){
        this.isAuthenticated.set(true)
      }
    }
    return this.isAuthenticated();
  }

}
